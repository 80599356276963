import {
  velocityConfig,
  opponentConfig,
  gameConfig,
  tutorialConfig
} from '../../config'
import type { Athlete } from './Athlete'
import {
  BlueBoxTextType,
  MinIdealStates
} from '@/app/types'
import { modes } from '@powerplay/core-minigames'
import {
  uiState,
  mainState,
  gamePhaseState
} from '@/stores'

/**
 * Trieda pre spravu rychlosti hraca
 */
export class AthleteVelocityManager {

  /** Ci su inputy bloknute alebo nie */
  public inputsBlocked = false

  /** hodnoty pre speedbar, pre superov */
  public speedbarValues: number[] = []

  /** Sila ktorou upravujeme rychlost */
  private speedPower = velocityConfig.speedBar.minValue

  /** Minimalna idealna hodnota */
  private minIdealValue = 0

  /** Aktualna hodnota frameov pre freeze po kliku */
  private freezeFrames = 0

  /** Posledna nastavena hodnota pre freeze po kliku */
  private lastFreezeFrames = 0

  /** Aktualny stav pre min ideal - ci sme v ideali alebo nie */
  private minIdealState = MinIdealStates.outIdeal

  /** Posledny index pri sprave min idealu, aby sa vedelo, ci sa ma nieco vykonat alebo nie */
  private lastIndexMinIdeal = -1

  /** Pocitadlo frameov pre min ideal */
  private frameCounterMinIdeal = 1

  /** Raz za X frameov sa aktualne riesi uprava min idealu */
  private minIdealFrames = 1

  /** O aku hodnotu sa ma aktualne zvysovat min ideal */
  private minIdealAddValue = 0

  /** Pocitadlo frameov pre zmenu hodnoty speedbaru */
  private frameCounter = 0

  /** Aktualna hodnota indexu pre vytahovanie hodnot z pola hodnot pre speedbar */
  private speedbarValuesIndex = 0

  /** Ci sa ma updatovat pocet frameov po poslednom kliku */
  private updateFramesAfterLastInputActive = false

  /** Pocet framov po poslednom kliku */
  private framesAfterLastInput = 0

  /** kolko krat stlacil run */
  private numberOfRunInputs = 0

  /** pocet framov pod idealom */
  public framesUnderIdeal = 0

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Vratenie hodnoty speed bar
   * @returns hodnota speed baru
   */
  public getSpeedPower(): number {

    return this.speedPower

  }

  /**
   * Vratenie hodnoty speed bar
   * @returns hodnota speed baru
   */
  public setSpeedPower(speedPower: number): void {

    this.speedPower = speedPower

  }

  /**
   * Vratenie minimalnej hodnoty idealu
   * @returns hodnota
   */
  public getMinIdealValue(): number {

    return this.minIdealValue

  }

  /**
   * Nastavenie freeze po kliku
   */
  private setFreezeAfterInput(): void {

    const { freeze } = velocityConfig.speedBar

    let index = 3

    if (this.minIdealValue < freeze[0].limitMax) {

      index = 0

    } else if (this.minIdealValue < freeze[1].limitMax) {

      index = 1

    } else if (this.minIdealValue < freeze[2].limitMax) {

      index = 2

    }

    this.freezeFrames = freeze[index].frames
    this.lastFreezeFrames = this.freezeFrames

  }

  /**
   * Kontrola inputov a spravenie veci, co sa maju pri nich vykonat
   */
  public handleInputs(): void {

    if (!this.athlete.speedManager.isActive() || this.inputsBlocked || !this.athlete.playable) return

    if (!this.updateFramesAfterLastInputActive) {

      console.log('Bol prvy klik')

    } else {

      console.log(`Posledny klik bol pred ${this.framesAfterLastInput} frameami`)
      this.framesAfterLastInput = 0

    }

    this.updateFramesAfterLastInputActive = true

    // console.warn(this.numberOfRunInputs)
    this.numberOfRunInputs += 1
    // console.warn(this.numberOfRunInputs)
    if (this.numberOfRunInputs === 3) {

      uiState().blueBoxTextType = BlueBoxTextType.hidden

    }
    this.addPower()
    this.setFreezeAfterInput()

  }

  /**
   * Zvysenie hodnoty baru
   */
  private addPower(): void {

    const { stepAdd, maxValue } = velocityConfig.speedBar
    let index = 3

    if (this.speedPower < stepAdd[0].limitMax) {

      index = 0

    } else if (this.speedPower < stepAdd[1].limitMax) {

      index = 1

    } else if (this.speedPower < stepAdd[2].limitMax) {

      index = 2

    }

    const min = stepAdd[index].minAdd
    const max = stepAdd[index].maxAdd
    const percentFreezeLeft = this.lastFreezeFrames === 0 ? -1 : this.freezeFrames / this.lastFreezeFrames

    if (percentFreezeLeft === -1) {

      this.speedPower += stepAdd[index].baseAdd

    } else {

      this.speedPower += Math.round(min + (percentFreezeLeft * (max - min)))

    }

    if (this.speedPower > maxValue) this.speedPower = maxValue

  }

  /**
   * Znizenie hodnoty baru
   */
  private removePower(): void {

    if (this.athlete.playable && gameConfig.autoMove.isEnabled) return
    // ak je freeze, tak neklesa bar, takisto to neplati pre supera, ten ma napevno hodnoty
    if (this.freezeFrames > 0 || !this.athlete.playable) return

    const { stepRemove, minValueAfterStart } = velocityConfig.speedBar

    let index = 3

    if (this.minIdealValue < stepRemove[0].limitMax) {

      index = 0

    } else if (this.minIdealValue < stepRemove[1].limitMax) {

      index = 1

    } else if (this.minIdealValue < stepRemove[2].limitMax) {

      index = 2

    }

    this.speedPower -= stepRemove[index].value
    if (this.speedPower < minValueAfterStart) this.speedPower = minValueAfterStart

  }

  /**
   * Nastavenie rychlosti z pola
   */
  private setSpeedFromArray(): void {

    // Hrac ide podla inputov a nie pola
    if (this.athlete.playable) return

    this.frameCounter += 1

    let changeValueEveryX = opponentConfig.speedbar.changeValueEveryX
    if (modes.isTutorial()) changeValueEveryX = tutorialConfig.opponent.changeValueEveryX

    if (
      this.frameCounter % changeValueEveryX === 0 ||
            this.speedPower === 0
    ) {

      this.speedPower = this.speedbarValues[this.speedbarValuesIndex]
      this.speedbarValuesIndex += 1
      if (this.speedbarValuesIndex >= this.speedbarValues.length) {

        this.speedbarValuesIndex = 0

      }

    }

  }

  /**
   * Sprava freezu po kliku
   */
  private manageFreeze(): void {

    if (this.freezeFrames > 0) this.freezeFrames -= 1


  }

  /**
   * Sprava min idealu, ked nie je bar v ideale
   */
  private manageMinIdealValueOutIdeal(): void {

    if (this.minIdealState === MinIdealStates.inIdeal) {

      // zresetujeme
      this.frameCounterMinIdeal = 1
      this.minIdealState = MinIdealStates.outIdeal
      this.minIdealFrames = velocityConfig.speedBar.minIdeal.decrease.frames
      this.minIdealAddValue = velocityConfig.speedBar.minIdeal.decrease.value

    }

  }

  /**
   * Sprava min idealu, ked je bar v ideale
   */
  private manageMinIdealValueInIdeal(): void {

    if (this.minIdealState === MinIdealStates.outIdeal) {

      // zresetujeme
      this.frameCounterMinIdeal = 1
      this.minIdealState = MinIdealStates.inIdeal
      this.lastIndexMinIdeal = -1

    }

    let index = 3
    const { increase } = velocityConfig.speedBar.minIdeal

    if (this.minIdealValue < increase[0].limitMax) {

      index = 0

    } else if (this.minIdealValue < increase[1].limitMax) {

      index = 1

    } else if (this.minIdealValue < increase[2].limitMax) {

      index = 2

    }

    // pokial je index rovnaky, tak nemame preco menit pocet frameov a hodnotu
    if (this.lastIndexMinIdeal === index) return

    this.lastIndexMinIdeal = index
    this.minIdealFrames = increase[index].frames
    this.minIdealAddValue = increase[index].value

  }

  /**
   * Sprava min ideal hodnoty
   */
  private manageMinIdealValue(): void {

    const { minIdealValue, maxIdealValue } = velocityConfig.speedBar

    if (this.speedPower < this.minIdealValue || this.speedPower > maxIdealValue) {

      // sme mimo idealu
      this.manageMinIdealValueOutIdeal()

    } else {

      // sme v ideali
      this.manageMinIdealValueInIdeal()

    }

    if (this.frameCounterMinIdeal % this.minIdealFrames === 0) {

      this.minIdealValue += this.minIdealAddValue
      if (this.minIdealValue > minIdealValue.max) this.minIdealValue = minIdealValue.max
      if (this.minIdealValue < minIdealValue.min) this.minIdealValue = minIdealValue.min

    }

    this.frameCounterMinIdeal += 1

  }

  /**
   * Aktualizovanie rychlosti
   */
  public update(): void {

    if (this.updateFramesAfterLastInputActive) {

      this.framesAfterLastInput += 1

    }

    this.setSpeedFromArray()
    this.removePower()
    this.manageFreeze()
    this.manageMinIdealValue()

    // UI update - iba pre hraca
    if (!this.athlete.playable) return

    mainState().speed = this.athlete.speedManager.getActualSpeed()
    const percentBar = this.speedPower / velocityConfig.speedBar.maxIdealValue * 100
    gamePhaseState().speedPower = percentBar
    const percentMark = this.minIdealValue / velocityConfig.speedBar.maxIdealValue * 100
    gamePhaseState().mark = percentMark
    this.checkUnderMinIdealFrames()

  }

  /**
   * Koklo framov som pod idealom
   */
  private checkUnderMinIdealFrames(): void {

    if (this.speedPower < this.minIdealValue) {

      this.framesUnderIdeal += 1

    } else {

      this.framesUnderIdeal = 0

    }


  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.speedPower = velocityConfig.speedBar.minValue
    this.minIdealValue = 0
    this.freezeFrames = 0
    this.minIdealState = MinIdealStates.outIdeal
    this.lastIndexMinIdeal = -1
    this.frameCounterMinIdeal = 1
    this.minIdealFrames = 1
    this.minIdealAddValue = 0
    this.inputsBlocked = false
    this.frameCounter = 0
    this.speedbarValuesIndex = 0

    this.speedbarValues = []
    this.lastFreezeFrames = 0
    this.updateFramesAfterLastInputActive = false
    this.framesAfterLastInput = 0
    this.numberOfRunInputs = 0

  }

}

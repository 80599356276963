<template>
  <div
    v-if="gamePhaseState.showSmallActionButtons"
    class="speed-bar"
  >
    <speed-bar v-if="showBar" />
  </div>
</template>

<script lang="ts">
import SpeedBar from './SpeedBar.vue'

import { defineComponent } from 'vue'
import { modes } from '@powerplay/core-minigames'
import {
  tutorialState,
  gamePhaseState
} from '@/stores'

export default defineComponent({
  name: 'EfficiencyComponent',
  components: {
    SpeedBar
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  data() {

    return {
      tutorialState: tutorialState(),
      gamePhaseState: gamePhaseState()
    }

  },
  computed: {
    showBar() {

      if (modes.isTutorial() && this.tutorialState.showSpeedBar) return false
      return this.gamePhaseState.showBar

    }
  }
})

</script>

<style lang='less' scoped>
.speed-bar {
  position: absolute;
  top: 60%;
  left: 55%;
  transform: translate(-50%, -50%);
}
</style>

import { defineStore } from 'pinia'
import type { BlueBoxTextType } from '@/app/types'


export interface UiState {
  showTimeKeeper: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean,
  blueBoxTextType: BlueBoxTextType,
  showWind: boolean
}

const initialState = (): UiState => ({
  showTimeKeeper: false,
  showTrainingLayout: false,
  isTraining: false,
  blueBoxTextType: -1,
  showWind: false
})

export const uiState = defineStore('uiState', {
  state: initialState
})

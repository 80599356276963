<template>
  <div
    class="traffic-component"
    :style="{transform: `scale(${scaleCoef})`}"
  >
    <start-up-text
      v-if="startMessageState.showMessage2"
      :type="startMessageState.messageType2"
      class="start-up-text"
      :is-scaled="false"
    />
    <traffic-light-box
      v-if="startMessageState.showMessage1"
      is-v2
      :color="startMessageState.messageColor1"
      :text="$t(startMessageState.messageText1)"
      :is-scaled="false"
      :with-animation="true"
      :delay-start="0"
      :style="getStyleTrafficBox"
    />
  </div>
</template>

<script lang="ts">
import {
  TrafficLightBox,
  StartUpText,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import { startMessageState } from '@/stores'

export default defineComponent({
  components: {
    TrafficLightBox,
    StartUpText
  },
  mixins: [WindowAspect],
  data() {

    return {
      startMessageState: startMessageState()
    }

  },
  computed: {
    getStyleTrafficBox() {

      return {
        'transform-origin': 'center top'
      }

    }
  }
})

</script>

<style lang="less" scoped>
.traffic-component {
  position: absolute;
  top: 14%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  transform-origin: top;
}
</style>

console.log('iii player')
import {
  THREE,
  game,
  cameraManager,
  errorManager,
  playersManager,
  CameraStates,
  modes,
  minigameConfig,
  timeManager,
  gsap
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig,
  lungeConfig,
  tutorialConfig,
  pathsConfig
} from '../../../config'
import {
  BlueBoxTextType,
  DisciplinePhases,
  LungeStates,
  ModelsNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../../types'
import { Athlete } from '..'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { opponentsManager } from '../opponent/OpponentsManager'
import { linesManager } from '../../env/LinesManager'
import { worldEnv } from '../../env/WorldEnv'
import {
  uiState,
  tutorialState,
  gamePhaseState
} from '@/stores'

/**
 * Trieda pre hraca
 */
export class Player extends Athlete {

  /** Ci je dokoncena transformacia kamery v speed locku */
  public speedLockCameraTransformationComplete = false

  /** stara player position pred resetom */
  private oldPlayerPos: {[key in CameraStates]: THREE.Vector3 | undefined} = {
    [CameraStates.intro]: undefined,
    [CameraStates.disciplineIntro]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.intro2]: undefined,
    [CameraStates.static]: undefined,
    [CameraStates.table]: undefined,
  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const meshAthleteName = modelsConfig[ModelsNames.athlete]?.mainMeshNames?.[0]
    if (!meshAthleteName) {

      throw new Error(errorManager.showBox('Mesh name for athlete was not defined'))

    }

    return game.getObject3D(meshAthleteName)

  }

  /**
   * Vytvorenie lyziara
   * @param trackIndex - Index drahy
   */
  public create(trackIndex: number): void {

    console.log('vytvaram hraca...')

    this.uuid = playersManager.getPlayer().uuid

    let trackIndexEdited = trackIndex
    if (modes.isTrainingMode() || modes.isTutorial()) trackIndexEdited = 5

    super.create(trackIndexEdited, 'Player', ModelsNames.athlete)

    if (!modes.isTutorial()) return
    this.worldEnvLinesManager.generateLungePosition(tutorialConfig.playerLungeFirstRun)

  }

  /**
   * Aktualizovanie kamera konfigu na zaciatku este
   * @param cameraState - Kamerovy stav
   */
  public updateCameraConfigOnStart(cameraState: CameraStates): void {

    const playerPosition = player.getPosition().clone()

    const cameraData = cameraManager.getTweenSettings()[cameraState]
    if (!cameraData) return

    const cameraDataFromState = (Array.isArray(cameraData)) ? cameraData[0] : cameraData

    // po resete chceme resetnut nastavenia na povodne
    if (this.oldPlayerPos[cameraState]) {

      cameraDataFromState.startPosition.sub(this.oldPlayerPos[cameraState] as THREE.Vector3)
      cameraDataFromState.endPosition.sub(this.oldPlayerPos[cameraState] as THREE.Vector3)

    }
    this.oldPlayerPos[cameraState] = playerPosition.clone()

    cameraDataFromState.startPosition.add(playerPosition)
    cameraDataFromState.endPosition.add(playerPosition)

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    console.warn(this.worldEnvLinesManager.lungeOffset)
    if (this.worldEnvLinesManager.lungeOffset > 0) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.lunge as string)

    } else {

      tutorialObjectives.failObjective(TutorialObjectiveIds.lunge as string)

    }
    if (opponentsManager.getOpponents()[0]?.finalTime === minigameConfig.dnfValue) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.beatOpponent as string)

    } else {

      tutorialObjectives.failObjective(TutorialObjectiveIds.beatOpponent as string)

    }
    // najskor musime ukoncit moznost pohybovych animacii
    this.activeUpdatingMovementAnimations = false

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp
    )

  }

  /**
   * nastavime camera settings podla game configu
   *
   * @param lerpSize - volitelny iny lerp ako v game configu
   */
  public setGameCameraSettings(lerpSize = gameConfig.cameraConfig.changeLerp): void {

    this.changeCameraSettings(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      lerpSize
    )

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    super.finishReached(actualPosition, lastPosition, finishPosition)

    uiState().blueBoxTextType = BlueBoxTextType.hidden
    uiState().showWind = true

    playersManager.setPlayerResults(this.finalTime)

    disciplinePhasesManager.phaseRunning.finishPhase()

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param actualPhase - Aktualna faza
   * @param inTop3 - Ci je hrac v top 3
   */
  public updateBeforePhysics(actualPhase: DisciplinePhases, inTop3 = false): void {

    super.updateBeforePhysics(actualPhase, inTop3)

    if (this.worldEnvLinesManager.isLungeEnabled() && gameConfig.autoMove.isEnabled) {

      player.startLunge()

    }

  }

  /**
   * Aktivacia speed lock-u
   */
  public activateSpeedLock(): void {

    super.activateSpeedLock()

    this.changeSpeedLockSpeed(lungeConfig.speedLockCoefCameraChange)

    tutorialState().showInstructionBox = false
    gamePhaseState().showBar = false
    uiState().blueBoxTextType = BlueBoxTextType.hidden

    disciplinePhasesManager.phaseRunning.showLungeInfo = true
    worldEnv.setVisibilityTextPlayer(true, true)
    const { speedLockCoefFinishLine, cameraOffset, cameraTransitionDuration } = lungeConfig

    cameraManager.setTLookAtCoef(1)

    const cameraOffsetEdited = cameraOffset.clone()
    // kameru posunieme o 1 drahu viac ako je zostavajuci pocet drah medzi kamerou a hracom
    const paths = player.worldEnvLinesManager.pathIndex <= 4 ?
      (player.worldEnvLinesManager.pathIndex + 3) :
      ((linesManager.getPaths().length - player.worldEnvLinesManager.pathIndex + 1) * -1)
    cameraOffsetEdited.x += (paths * pathsConfig.pathWidth)
    // cameraConfig.tweenSettingsForCameraStates.disciplineOutro.startPosition.x = -cameraOffsetEdited.x
    // cameraConfig.tweenSettingsForCameraStates.disciplineOutro.endPosition.x = -cameraOffsetEdited.x
    const cameraOffsetTransition = player.worldEnvLinesManager.actualIdealOffset.clone()
    gsap.to(cameraOffsetTransition, {
      duration: cameraTransitionDuration,
      x: cameraOffsetEdited.x,
      z: cameraOffsetEdited.z,
      y: cameraOffsetEdited.y,
      onUpdate: () => {

        cameraManager.changeIdeals(cameraOffsetTransition)

      },
      onComplete: () => {

        const blueBoxType = modes.isTutorial() ? BlueBoxTextType.hidden : BlueBoxTextType.lunge
        uiState().blueBoxTextType = blueBoxType
        this.changeSpeedLockSpeed(speedLockCoefFinishLine)
        this.speedLockCameraTransformationComplete = true

      }
    })

  }

  /**
   * Deaktivovanie spomalenia
   */
  public deactivateSpeedLock(): void {

    super.deactivateSpeedLock()

    this.speedLockCameraTransformationComplete = false
    this.changeSpeedLockSpeed(1)

  }

  /**
   * Zmena speedLockCoef a rychlosti animacii a casu
   * @param speedLockCoef - number
   */
  private changeSpeedLockSpeed(speedLockCoef: number): void {

    console.log('SPEED LOCK COEF -> ', speedLockCoef)
    disciplinePhasesManager.phaseRunning.speedLockCoef = speedLockCoef
    timeManager.changeTimeSpeed(disciplinePhasesManager.phaseRunning.speedLockCoef)
    player.animationsManager.setSpeed(disciplinePhasesManager.phaseRunning.speedLockCoef)
    opponentsManager.getOpponents().forEach(opponent => {

      opponent.animationsManager.setSpeed(disciplinePhasesManager.phaseRunning.speedLockCoef)

    })

  }

  /**
   * Zapnutie lunge-u
   */
  public startLunge(): void {

    // aby sa nespustalo viackrat a aby to bolo v zone, kedy to je mozne dat
    if (this.lungeState !== LungeStates.none || !this.worldEnvLinesManager.isLungeEnabled()) return

    super.startLunge()
    uiState().blueBoxTextType = BlueBoxTextType.hidden
    tutorialFlow.eventActionTrigger(TutorialEventType.autoLunge)

  }


  /**
   * Spracovanie lunge-u pre hraca
   */
  public handleLunge(): void {

    if (this.speedLockCameraTransformationComplete) this.startLunge()

  }

}

export const player = new Player('')

import { defineStore } from 'pinia'

export interface GamePhaseState {
  showBar: boolean,
  speedPower: number,
  showSmallActionButtons: boolean,
  disabledSmallAction: boolean,
  showUi: boolean,
  mark: number,
  showRecords: boolean
}

const initialState = (): GamePhaseState => ({
  showBar: false,
  speedPower: 0,
  showSmallActionButtons: true,
  disabledSmallAction: false,
  showUi: true,
  mark: 100,
  showRecords: false
})

export const gamePhaseState = defineStore('gamePhaseState', {
  state: initialState
})

import { defineStore } from 'pinia'

export interface LungeState {
  showLungeInfo: boolean,
  text: string,
  fill: number,
  color: string,
  messageType: string,
  showInfo: boolean
}

const initialState = (): LungeState => ({
  showLungeInfo: false,
  text: '',
  fill: 0,
  color: '',
  messageType: '',
  showInfo: false
})

export const lungeState = defineStore('lungeState', {
  state: initialState
})

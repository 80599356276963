import {
  animationsConfig,
  gameConfig,
  specialAnimationConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'
import {
  BlueBoxTextType,
  EmotionTypesFinish,
  EndTypesFinish,
  LungeStates,
  PlayerAnimationsNames,
  PlayerStates
} from '@/app/types'
import {
  CallbackAnimationTypes,
  PlayerSex,
  gsap,
  modes,
  playersManager
} from '@powerplay/core-minigames'
import type { Athlete } from './Athlete'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { uiState } from '@/stores'

/**
 * Dedikovany manazer animacii pre hraca/superov.
 */
export class AthleteAnimationManager {

  /** ci sme uz nastavili podla stavov veci */
  private statesActive = {
    [PlayerStates.prepare]: false,
    [PlayerStates.prepareSpecial]: false,
    [PlayerStates.prepare2]: false,
    [PlayerStates.onYourMarks]: false,
    [PlayerStates.set]: false,
    [PlayerStates.setToStartRun]: false,
    [PlayerStates.startRun]: false,
    [PlayerStates.falseStartSlowDown]: false,
    [PlayerStates.run]: false,
    [PlayerStates.end]: false,
    [PlayerStates.endPhaseTwo]: false,
    [PlayerStates.endPath]: false
  }

  /** aktualna animacia - TODO rozsir o vsetky animacie mimo konecnej */
  public actualAnimation?: PlayerAnimationsNames

  /** ci sme nastavili konecnu animaciu */
  public isEndEmotionSet = false

  /** Tween pre animaciu slow down run */
  public tweenSlowDownRun?: gsap.core.Tween

  /** buduca mozna animacia kvoli crossfadeu */
  private possibleNextAnimation?: PlayerAnimationsNames

  /** Prepare animacia */
  private prepareAnimation?: PlayerAnimationsNames

  /** Tween do run */
  private tweenToRun?: gsap.core.Tween

  /** Pocitadlo pre zvuky krokov */
  private footstepsAudioCounter = 0

  /** Ktory index sa zahral naposledy */
  private lastFootstepsAudioIndex = 0

  /** Pole moznych cisel pre zvuky krokov */
  private footstepsAudioNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  /** Animacie, ktore su bezecke, pri ktorych davame zvuk krokov */
  private readonly runningAnimations = [
    PlayerAnimationsNames.setToRun,
    PlayerAnimationsNames.startRun,
    PlayerAnimationsNames.run,
    PlayerAnimationsNames.lunge,
    PlayerAnimationsNames.endHappy1,
    PlayerAnimationsNames.endHappy2,
    PlayerAnimationsNames.endHappy3,
    PlayerAnimationsNames.stopToBreathing,
    PlayerAnimationsNames.slowDownRun
  ]

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {

    this.shuffleFootstepsAudioNumbers()

  }

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    this.athlete.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    this.athlete.animationsManager.resetSpeed()

  }

  /**
   * Vratenie percenta pre animacie podla cisla trate atleta
   * @returns Percento
   */
  private getPercentByTrackNumber(): number {

    return specialAnimationConfig.randomPercentByPathIndex[this.athlete.worldEnvLinesManager.pathIndex - 1]

  }

  /**
   * Poprehadzovanie poradia zvukov krokov, aby sa neopakovali
   */
  private shuffleFootstepsAudioNumbers(): void {

    function shuffle(array: number[]) {

      let currentIndex = array.length

      // While there remain elements to shuffle...
      while (currentIndex != 0) {

        // Pick a remaining element...
        const randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]]

      }

    }

    shuffle(this.footstepsAudioNumbers)

  }

  /**
   * Skipnutie rozbehu, hlavne kvoli false start
   */
  public skipStartRun(): void {

    this.tweenToRun?.progress(1)

  }

  /**
   * Start animacie prepare
   */
  private startPrepareAnimation(): void {

    let animation = PlayerAnimationsNames.prepare
    const random = Math.floor(Math.random() * 5)

    if (random === 0) animation = PlayerAnimationsNames.prepare1
    if (random === 1) animation = PlayerAnimationsNames.prepare2
    if (random === 2) animation = PlayerAnimationsNames.prepare3
    if (random === 3) animation = PlayerAnimationsNames.prepare4

    // ak bol random 1, tak nerobime nic a nechavame original startovaciu prepare animaciu
    if (animation !== PlayerAnimationsNames.prepare) {

      this.prepareAnimation = animation
      this.possibleNextAnimation = animation

      this.athlete.animationsManager.changeTo(animation)

    }

    // zmena casu animacie
    this.athlete.animationsManager.manualyUpdateTimeByPercent(animation, this.getPercentByTrackNumber())

  }

  /**
   * Riesenie veci pre game start stav
   * @returns True, ak ide o dany stav
   */
  private isGameStartState(): boolean {

    if (!modes.isTutorial() && !this.statesActive[PlayerStates.prepare]) {

      this.statesActive[PlayerStates.prepare] = true
      this.startPrepareAnimation()

    }

    return true

  }

  /**
   * Riesenie veci pre specialny prepare stav
   * @returns True, ak ide o dany stav
   */
  private isPrepareSpecialState(): boolean {

    const isPrepareSpecial = this.athlete.isState(PlayerStates.prepareSpecial)

    if (!this.statesActive[PlayerStates.prepareSpecial] && isPrepareSpecial) {

      this.statesActive[PlayerStates.prepareSpecial] = true

      const random = Math.floor(Math.random() * 3)
      let specialAnimation = PlayerAnimationsNames.prepareHand
      if (random === 0) specialAnimation = PlayerAnimationsNames.prepareHands
      if (random === 1) {

        const athleteSex = playersManager.getPlayerById(this.athlete.uuid)?.sex ?? PlayerSex.male
        specialAnimation = athleteSex === PlayerSex.male ?
          PlayerAnimationsNames.prepareSalut :
          PlayerAnimationsNames.prepareKisses

      }

      this.athlete.animationsManager.setSpeed(1.3)
      this.athlete.animationsManager.changeTo(specialAnimation)

    }

    return isPrepareSpecial

  }

  /**
   * Riesenie veci pre prepare2 stav
   * @returns True, ak ide o dany stav
   */
  private isPrepare2State(): boolean {

    const isPrepare2 = this.athlete.isState(PlayerStates.prepare2)

    if (!this.statesActive[PlayerStates.prepare2] && isPrepare2) {

      this.statesActive[PlayerStates.prepare2] = true

      this.athlete.animationsManager.resetSpeed()
      if (this.prepareAnimation) this.athlete.animationsManager.changeTo(this.prepareAnimation)

    }

    return isPrepare2

  }

  /**
   * Riesenie veci pre ground stav
   * @returns True, ak ide o dany stav
   */
  private isGroundState(): boolean {

    const isGround = this.athlete.isState(PlayerStates.onYourMarks)

    if (!this.statesActive[PlayerStates.onYourMarks] && isGround) {

      this.statesActive[PlayerStates.onYourMarks] = true
      console.log('ground animacia', this.athlete.uuid)
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.idleGroundOnStart)

    }

    return isGround

  }

  /**
   * Riesenie veci pre set stav
   * @returns True, ak ide o dany stav
   */
  private isSetState(): boolean {

    const isSet = this.athlete.isState(PlayerStates.set)

    if (!this.statesActive[PlayerStates.set] && isSet) {

      this.statesActive[PlayerStates.set] = true

      // hratelny hrac moze odstartovat az ked dobehne animacia ground to set
      if (this.athlete.playable) {

        this.athlete.animationsManager.addAnimationCallback(
          PlayerAnimationsNames.groundToSet,
          CallbackAnimationTypes.end,
          () => {

            disciplinePhasesManager.phaseStart.startable = true
            startPhaseStateManager.enableInputs(true)
            uiState().blueBoxTextType = BlueBoxTextType.run

            this.athlete.animationsManager.removeAnimationCallback(
              PlayerAnimationsNames.groundToSet,
              CallbackAnimationTypes.end
            )

          }
        )

      }

      const { min, max } = specialAnimationConfig.groundToSet
      const duration = min + (this.getPercentByTrackNumber() / 100 * (max - min))
      gsap.to({}, {
        duration,
        onComplete: () => {

          this.athlete.animationsManager.changeTo(PlayerAnimationsNames.groundToSet)

        }
      })

    }

    return isSet

  }

  /**
   * Riesenie veci pre set to start run stav
   * @returns True, ak ide o dany stav
   */
  private isSetToStartRunState(): boolean {

    const isSetToStartRun = this.athlete.isState(PlayerStates.setToStartRun)

    if (!this.statesActive[PlayerStates.setToStartRun] && isSetToStartRun) {

      this.statesActive[PlayerStates.setToStartRun] = true

      this.athlete.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.setToRun,
        CallbackAnimationTypes.end,
        () => {

          this.athlete.animationsManager.removeAnimationCallback(
            PlayerAnimationsNames.setToRun,
            CallbackAnimationTypes.end
          )
          this.athlete.setState(PlayerStates.startRun)

        }
      )

      this.athlete.animationsManager.setSpeed(1.3)
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.setToRun)

    }

    return isSetToStartRun

  }

  /**
   * Riesenie veci pre start run stav
   * @returns True, ak ide o dany stav
   */
  private isStartRunState(): boolean {

    const isStartRun = this.athlete.isState(PlayerStates.startRun)

    if (!this.statesActive[PlayerStates.startRun] && isStartRun) {

      this.statesActive[PlayerStates.startRun] = true

      // kedze je startRun loop animacia a prehrava sa na pozadi, tak musime resetovat cas, aby to sedelo
      this.athlete.animationsManager.resetAnimationTime(PlayerAnimationsNames.startRun)
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.startRun)
      // davame hned state run, aby sa rovno robil crossfade do behu
      this.athlete.setState(PlayerStates.run)

    }

    return isStartRun

  }

  /**
   * Riesenie veci pre false start slow down stav
   * @returns True, ak ide o dany stav
   */
  private isFalseStartSlowDownState(): boolean {

    const isFalseStartSlowDown = this.athlete.isState(PlayerStates.falseStartSlowDown)

    if (!this.statesActive[PlayerStates.falseStartSlowDown] && isFalseStartSlowDown) {

      this.statesActive[PlayerStates.falseStartSlowDown] = true

      // zrusime start run callback
      this.athlete.animationsManager.removeAnimationCallback(
        PlayerAnimationsNames.startRun,
        CallbackAnimationTypes.loop
      )

      this.possibleNextAnimation = PlayerAnimationsNames.slowDownRun
      this.tweenToRun?.progress(1)

      const { min, max } = specialAnimationConfig.slowDownRun
      const duration = min + (this.getPercentByTrackNumber() / 100 * (max - min))

      this.tweenSlowDownRun = gsap.to({}, {
        duration,
        onComplete: () => {

          this.athlete.animationsManager.crossfadeTo(
            PlayerAnimationsNames.slowDownRun,
            0.4,
            true,
            true
          )

        }
      })

    }

    return isFalseStartSlowDown

  }

  /**
   * Riesenie veci pre run stav
   * @returns True, ak ide o dany stav
   */
  private isRunState(): boolean {

    const isRun = this.athlete.isState(PlayerStates.run)

    if (!this.statesActive[PlayerStates.run] && isRun) {

      this.statesActive[PlayerStates.run] = true

      const startRunAnimTime = this.athlete.animationsManager.getAnimationActualTime(PlayerAnimationsNames.startRun)
      this.athlete.animationsManager.getAnimationAction(PlayerAnimationsNames.run).time = startRunAnimTime
      const minSpeed = 1
      const maxSpeed = 1.3
      this.possibleNextAnimation = PlayerAnimationsNames.startRun

      const { min, max } = specialAnimationConfig.run
      const delay = disciplinePhasesManager.phaseStart.wasFalseStart ?
        0 :
        (min + (this.getPercentByTrackNumber() / 100 * (max - min)))

      this.tweenToRun = gsap.to({}, {
        duration: 1,
        delay,
        onUpdate: () => {

          const percent = this.tweenToRun?.progress() ?? 0

          this.athlete.animationsManager.manualCrossfadeTo(
            PlayerAnimationsNames.startRun,
            PlayerAnimationsNames.run,
            percent
          )

          const speed = maxSpeed - ((maxSpeed - minSpeed) * percent)
          this.athlete.animationsManager.setSpeed(speed)

        },
        onComplete: () => {

          // musime resetovat rychlost a zmenit animaciu na run, lebo inak sa neprepise actualAnimation
          this.athlete.animationsManager.resetSpeed()
          this.athlete.animationsManager.changeTo(PlayerAnimationsNames.run)

        }
      })


    }

    return isRun

  }

  /**
   * Riesenie veci pre lunge stav
   * @returns True, ak ide o dany stav
   */
  private isLungeState(): boolean {

    const isLunge = this.athlete.isState(PlayerStates.lunge)

    if (isLunge) {

      let percent = -1
      if (this.athlete.lungeState === LungeStates.forward) {

        percent = this.athlete.lungeActualFrame / this.athlete.getLungeMaxFrames()

      }
      if (this.athlete.lungeState === LungeStates.backward) {

        percent = 1 - (this.athlete.lungeActualFrame / this.athlete.getLungeMaxFrames())

      }

      if (percent > -1) {

        this.possibleNextAnimation = PlayerAnimationsNames.lunge
        this.athlete.animationsManager.manualCrossfadeTo(
          PlayerAnimationsNames.run,
          PlayerAnimationsNames.lunge,
          percent
        )

      }

    }

    return isLunge

  }

  /**
   * Zistenie konkretnej animacie end happy
   * @returns Animacia
   */
  private getEndHappyAnimation(): PlayerAnimationsNames {

    const random = Math.ceil(Math.random() * 3)
    let animation = PlayerAnimationsNames.endHappy1
    if (random === 1) animation = PlayerAnimationsNames.endHappy2
    if (random === 2) animation = PlayerAnimationsNames.endHappy3

    return animation

  }

  /**
   * Riesenie veci pre end stav
   * @returns True, ak ide o dany stav
   */
  private isEndState(): boolean {

    const isEnd = this.athlete.isState(PlayerStates.end)

    if (!this.statesActive[PlayerStates.end] && isEnd) {

      this.statesActive[PlayerStates.end] = true

      // dokoncime este lunge, nech nie su problemy
      this.athlete.animationsManager.manualCrossfadeTo(
        PlayerAnimationsNames.run,
        PlayerAnimationsNames.lunge,
        0
      )

      const animation = this.athlete.emotionFinish === EmotionTypesFinish.winner ?
        this.getEndHappyAnimation() :
        PlayerAnimationsNames.slowDownRun
      this.possibleNextAnimation = animation
      this.athlete.animationsManager.crossfadeTo(animation, 0.2, true, false)

    }

    return isEnd

  }

  /**
   * Riesenie veci pre end fazu 2 stav
   * @returns True, ak ide o dany stav
   */
  private isEndPhaseTwoState(): boolean {

    const isEndPhaseTwo = this.athlete.isState(PlayerStates.endPhaseTwo)

    if (!this.statesActive[PlayerStates.endPhaseTwo] && isEndPhaseTwo) {

      this.statesActive[PlayerStates.endPhaseTwo] = true

      if (this.athlete.endPhaseTwoFinish !== EndTypesFinish.walk) {

        this.athlete.animationsManager.addAnimationCallback(
          PlayerAnimationsNames.stopToBreathing,
          CallbackAnimationTypes.end,
          () => {

            this.athlete.animationsManager.removeAnimationCallback(
              PlayerAnimationsNames.stopToBreathing,
              CallbackAnimationTypes.end
            )

            this.possibleNextAnimation = PlayerAnimationsNames.breathing
            this.athlete.animationsManager.crossfadeTo(PlayerAnimationsNames.breathing, 0.2, true, false)

          }
        )
        this.athlete.animationsManager.changeTo(PlayerAnimationsNames.stopToBreathing)

      }

    }

    return isEndPhaseTwo

  }

  /**
   * Riesenie veci pre koniec trate stav
   * @returns True, ak ide o dany stav
   */
  private isEndPathState(): boolean {

    const isEndPath = this.athlete.isState(PlayerStates.endPath)

    if (!this.statesActive[PlayerStates.endPath] && isEndPath) {

      this.statesActive[PlayerStates.endPath] = true

      this.possibleNextAnimation = PlayerAnimationsNames.breathing
      this.athlete.animationsManager.crossfadeTo(PlayerAnimationsNames.breathing, 0.2, true, false)

    }

    return isEndPath

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    this.isEndPathState()
    this.isEndPhaseTwoState()
    this.isEndState()
    this.isLungeState()
    this.isRunState()
    this.isFalseStartSlowDownState()
    this.isStartRunState()
    this.isSetToStartRunState()
    this.isSetState()
    this.isGroundState()
    this.isPrepare2State()
    this.isPrepareSpecialState()
    this.isGameStartState()

  }

  /**
   * Sprava zvukov krokov
   * @param animation - Animacia s ktorou sa aktualne pracuje
   */
  private manageFootstepsAudio(animation: PlayerAnimationsNames): void {

    const config = gameConfig.audioFootstepsTimes

    if (!config || config.length === 0) return

    const coefLeft = config[0]
    const coefRight = config[1]
    const animationAction = this.athlete.animationsManager.getAnimationAction(animation)
    const nearestLimit = this.footstepsAudioCounter % 2 === 0 ? coefLeft : coefRight
    const durationAnimation = animationAction.getClip().duration
    let time = animationAction.time

    if (!animationsConfig[animation].loop && time >= durationAnimation) return

    // ak sme nad druhym limitom, tak musime to akoby preniest do zapornych cisiel alebo pod prvy limit
    if (nearestLimit === coefLeft && time > coefRight) {

      time = time - durationAnimation

    }

    // bol dalsi doslap
    if (time > nearestLimit) {

      this.footstepsAudioCounter++

      audioHelper.playMovementAudio(this.footstepsAudioNumbers[this.lastFootstepsAudioIndex])
      this.lastFootstepsAudioIndex += 1
      if (this.lastFootstepsAudioIndex >= this.footstepsAudioNumbers.length) this.lastFootstepsAudioIndex = 0

    }

  }

  /**
   * Logika zvuku krokov
   */
  private footstepsAudioLogic(): void {

    // pre superov nedavame zvuky krokov
    if (!this.athlete.playable) return

    this.actualAnimation = this.athlete.animationsManager.getActualAnimation() as PlayerAnimationsNames

    if (!this.actualAnimation) return

    const actualAnimationAction = this.athlete.animationsManager.getAnimationAction(this.actualAnimation)
    const actualAnimationWeight = actualAnimationAction.getEffectiveWeight()
    const animationToWorkWith = actualAnimationWeight >= 0.5 ? this.actualAnimation : this.possibleNextAnimation

    if (animationToWorkWith && this.runningAnimations.includes(animationToWorkWith)) {

      this.manageFootstepsAudio(animationToWorkWith)

    } else {

      this.footstepsAudioCounter = 0

    }

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.animationLogic()
    this.footstepsAudioLogic()

    // keby niekto potreboval :)
    // const logAnimation = PlayerAnimationsNames.prepare
    // console.log(
    //   `ako su casy animacii ${logAnimation}?`,
    //   player.animationsManager.getAnimationAction(logAnimation).time,
    //   opponentsManager.getOpponents()[0].animationsManager.getAnimationAction(logAnimation).time,
    //   opponentsManager.getOpponents()[1].animationsManager.getAnimationAction(logAnimation).time,
    //   opponentsManager.getOpponents()[2].animationsManager.getAnimationAction(logAnimation).time,
    //   opponentsManager.getOpponents()[3].animationsManager.getAnimationAction(logAnimation).time,
    //   opponentsManager.getOpponents()[4].animationsManager.getAnimationAction(logAnimation).time,
    //   opponentsManager.getOpponents()[5].animationsManager.getAnimationAction(logAnimation).time,
    //   opponentsManager.getOpponents()[6].animationsManager.getAnimationAction(logAnimation).time,
    // )

  }

  /**
   * Zresetovanie veci
   */
  public reset(): void {

    this.actualAnimation = undefined
    this.isEndEmotionSet = false
    this.statesActive = {
      [PlayerStates.prepare]: disciplinePhasesManager.phaseStart.wasFalseStart,
      [PlayerStates.prepareSpecial]: false,
      [PlayerStates.prepare2]: false,
      [PlayerStates.onYourMarks]: false,
      [PlayerStates.set]: false,
      [PlayerStates.setToStartRun]: false,
      [PlayerStates.startRun]: false,
      [PlayerStates.falseStartSlowDown]: false,
      [PlayerStates.run]: false,
      [PlayerStates.end]: false,
      [PlayerStates.endPhaseTwo]: false,
      [PlayerStates.endPath]: false
    }

    this.footstepsAudioCounter = 0
    this.lastFootstepsAudioIndex = 0

  }

}

<template>
  <div
    class="info-component"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <info-box
      v-if="tableState.showTable"
    />
  </div>
  <div
    class="info-component-bluebox"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <blue-box
      v-if="uiState.blueBoxTextType >= 0"
      :side="'bottom'"
      show-border
      :style="{left: '50%', width: '610px', height: (isMobile() ? '67px' : '107px'), transformOrigin: 'bottom center',
               position: 'unset'}"
    >
      <arrow-animation
        v-if="glow"
        position="bottom"
        :style="{
          position: 'absolute',
          width: '100%',
          height: '100%',
          bottom: '20px'
        }"
        :is-scaled="false"
      />
      <div
        v-if="isMobile()"
        class="blue-box-content-mobile"
      >
        {{ getTextMobile }}
      </div>
      <div
        v-else
        class="blue-box-content-desktop"
      >
        <div class="first-line">
          {{ getFirstLineText }}
        </div>
        <div class="second-line">
          <span class="left">{{ $t('pressKey').replace('[KEY]', '') }}</span>
          <span class="right">SPACE /</span>
          <img
            src="https://appspowerplaymanager.vshcdn.net/images/minigame/core-ui/icons/ico-mouse.png"
          >
        </div>
      </div>
    </blue-box>
  </div>
</template>

<script lang="ts">
import {
  ArrowAnimation,
  BlueBox,
  InfoBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import { BlueBoxTextType } from '@/app/types/ui'
import {
  tableState,
  uiState
} from '@/stores'

export default defineComponent({
  name: 'InfoComponent',
  components: {
    InfoBox,
    BlueBox,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  props: {
    glow: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      tableState: tableState(),
      uiState: uiState()
    }

  },
  computed: {
    getFirstLineText() {

      let text = ''
      if (this.isMobile()) return text

      if (this.uiState.blueBoxTextType === BlueBoxTextType.run) text = this.$t('running')
      if (this.uiState.blueBoxTextType === BlueBoxTextType.lunge) text = this.$t('lunge')

      return text

    },
    getTextMobile() {

      let text = ''
      if (!this.isMobile()) return text

      if (this.uiState.blueBoxTextType === BlueBoxTextType.run) text = this.$t('runTapping')
      if (this.uiState.blueBoxTextType === BlueBoxTextType.lunge) text = this.$t('lungeTap')

      return text

    }
  },
})

</script>

<style lang="less" scoped>
.info-component {
   position: absolute;
   bottom: .5rem;
   width: 100%;
   display: flex;
}
.info-component-bluebox {
   position: absolute;
   bottom: .5rem;
   width: 100%;
   display: flex;
   justify-content: center;

   &.mobile-info-component {
      bottom: .5rem;
   }

   .blue-box-content-mobile {
    font-size: 32px;
    font-weight: normal;
    color: #fff;
   }

  .blue-box-content-desktop {
    .first-line {
      text-transform: uppercase;
      font-size: 32px;
      color: #feb942;
      font-weight: normal;
    }
    .second-line {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7px;

      .left {
        font-weight: normal;
      }

      .right {
        font-weight: 600;
      }
    }
  }
}
</style>

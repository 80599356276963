import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  gsap,
  TutorialMessageColors,
  MobileDetector,
  requestManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import { worldEnv } from '@/app/entities/env/WorldEnv'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { player } from '@/app/entities/athlete/player'
import { endManager } from '@/app/EndManager'
import {
  blurState,
  tutorialState
} from '@/stores'
import { tutorialCoreState } from '@powerplay/core-minigames-ui-ssm'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** aktualny event */
  private activeEventType = TutorialEventType.awaitingEvent

  /** ci uz skoncil prvu zakrutu */
  private firstFailedStartDone = false

  /** ci uz skoncil druhu zakrutu */
  public isSecondRun = false

  /** pocitadlo excelent a perfect impulzov */
  public correctImpulses = 0

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.start as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-1`
      },
      {
        id: TutorialObjectiveIds.lunge as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-2`
      },
      {
        id: TutorialObjectiveIds.beatOpponent as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-3`
      }
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    opponentsManager.isActive = false
    player.tutorialAutoLunge = true
    worldEnv.changeLightmapFromDarkToNormal()
    const tutorialSections = [
      {
        name: SectionNames.mobileSidePick,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false

        }
      },
      {
        name: SectionNames.introductionNewInstructor,
        id: 1,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false
          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSection,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false
          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionSecond,
        id: 3,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          tutorialState().buttons = {
            showJoystick: true,
            showMovementButtons: false
          }

        },
        sectionLogicFinish: () => {

          tutorialState().buttons = {
            showJoystick: false,
            showMovementButtons: false
          }
          this.resetTypeWrite()
          disciplinePhasesManager.phaseStart.launchSystem()

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 4,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionFourth,
        id: 5,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionFifth,
        id: 6,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionSixth,
        id: 7,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionSeventh,
        id: 8,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionEight,
        id: 9,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    // uplne prvy tutorial
    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) {

      if (MobileDetector.isMobile()) return

      tutorialManager.setActualSectionId(1)
      return

    }

    tutorialManager.setActualSectionId(2)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType

    if (eventType === TutorialEventType.autoLunge) {

      if (this.isSecondRun) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      } else {

        this.resetTypeWrite()
        game.pauseGame()
        tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-6`)
        tutorialUIChange.setAnne(true, false)

      }

    }
    if (eventType === TutorialEventType.failedStart) {

      tutorialObjectives.falseStartCount += 1
      if (this.firstFailedStartDone) {

        disciplinePhasesManager.phaseStart.finishFalseStart()
        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      this.firstFailedStartDone = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-3`)
      tutorialUIChange.setAnne(true, false)

    }
    if (eventType === TutorialEventType.goodStart) {

      if (this.isSecondRun) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-4`, undefined, undefined, true)
      tutorialUIChange.setAnne(true, true)

      tutorialState().showSpeedBar = true

    }
    if (eventType === TutorialEventType.firstFinish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-5`)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.goodFinish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-7`, TutorialMessageColors.green)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.badFinish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-9`, TutorialMessageColors.red)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.okFinish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-8`, TutorialMessageColors.red)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.tooSlow) {

      const text = MobileDetector.isMobile() ?
        `tutorialInfoBox${requestManager.disciplineID}-1m` :
        `tutorialInfoBox${requestManager.disciplineID}-1`

      tutorialState().instructionBoxText = text
      tutorialState().instructionBoxIcon = 1
      tutorialState().showInstructionBox = true

    }
    if (eventType === TutorialEventType.tooFast) {

      const text = MobileDetector.isMobile() ?
        `tutorialInfoBox${requestManager.disciplineID}-2m` :
        `tutorialInfoBox${requestManager.disciplineID}-2`

      tutorialState().instructionBoxText = text
      tutorialState().instructionBoxIcon = 0
      tutorialState().showInstructionBox = true

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()

    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (this.activeEventType === TutorialEventType.failedStart) {

      disciplinePhasesManager.phaseStart.finishFalseStart()

    }
    if (this.activeEventType === TutorialEventType.goodStart) {

      tutorialState().showSpeedBar = false

      tutorialManager.blockInputsManually()
      tutorialManager.nextSection()

    }
    if (this.activeEventType === TutorialEventType.firstFinish) {

      opponentsManager.isActive = true
      this.setObjectivesInit()
      endManager.reset()
      disciplinePhasesManager.resetAttempt()
      worldEnv.setVisibilityLineLeader(false)

    }
    if (
      [
        TutorialEventType.goodFinish,
        TutorialEventType.badFinish,
        TutorialEventType.okFinish
      ].includes(this.activeEventType)
    ) {

      tutorialManager.blockInputsManually()
      tutorialManager.nextSection(),
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

    }

    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * Po prejdeni ciela
   */
  public finishPassed(): void {

    gsap.to({}, {
      duration: 2,
      onComplete: () => {

        if (this.isSecondRun) {

          tutorialObjectives.time = Math.ceil(player.finalTime * 100) / 100
          if (tutorialObjectives.isAllObjectivesPassed()) {

            this.eventActionTrigger(TutorialEventType.goodFinish)
            return

          }
          if (tutorialObjectives.isSomeObjectivesPassed()) {

            this.eventActionTrigger(TutorialEventType.okFinish)
            return

          }
          this.eventActionTrigger(TutorialEventType.badFinish)
          return

        }
        this.isSecondRun = true
        this.eventActionTrigger(TutorialEventType.firstFinish)

      }
    })

  }

}

export const tutorialFlow = new TutorialFlow()

import {
  LungeStates,
  type DisciplinePhaseManager,
  TutorialEventType,
  Tasks
} from '../../types'
import {
  MobileDetector,
  gsap,
  modes,
  fpsManager
} from '@powerplay/core-minigames'
import { player } from '@/app/entities/athlete/player'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { lungeConfig } from '@/app/config'
import { worldEnv } from '@/app/entities/env/WorldEnv'
import { endManager } from '@/app/EndManager'
import { trainingTasks } from '@/app/modes/training'
import {
  gamePhaseState,
  movementButtonsState,
  actionButtonState,
  inputsState,
  tutorialState,
  lungeState
} from '@/stores'

/**
 * Trieda fazy pre zjazd
 */
export class RunningPhase implements DisciplinePhaseManager {

  /** koeficient na nasobenie rychlosti a casov pri speedLocku */
  public speedLockCoef = 1

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /** Ci je aktivna faza */
  public isActive = false

  /** ci zobrazit lunge info ui */
  public showLungeInfo = false

  /** na kolko je naplneny lunge info bar */
  public lungeInfoFill = 0

  /** lunge color */
  private lungeColor = ''

  /** message type lunge */
  private lungeMessageType = ''

  /** Pocitadlo frameov pre lunge */
  private frameCounterLunge = 0

  /** Ci sa maju zapisovat data */
  private collectingQualityRunActive = false

  /** Hodnoty kvalit behu */
  private runQualities: number[] = []

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    this.lungeInfoFill = 0
    console.warn('game phase started')
    this.isActive = true

  }

  /**
   * Zobrazenie bezeckeho UI (pri rozbehu este nebude)
   */
  public showRunningUi(): void {

    gamePhaseState().showBar = true
    movementButtonsState().isActive = true

    this.showMobileButtons()

  }

  /**
   * Zobrazenie mobilnych buttonov
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    actionButtonState().showJoystick = true
    inputsState().disabled = false

  }

  /**
   * Zaciatok zbieranie dat pre kvalitu behu
   */
  public startCollectingQualityRun(): void {

    this.collectingQualityRunActive = true

  }

  /**
   * Koniec zbieranie dat pre kvalitu behu
   */
  public stopCollectingQualityRun(): void {

    this.collectingQualityRunActive = false

  }

  /**
   * Zbieranie dat pre kvalitu behu
   * @param valueToCount - hodnota, ktora sa zapisuje
   */
  public collectPartQualityRun(valueToCount: number): void {

    if (!this.collectingQualityRunActive) return

    this.runQualities.push(valueToCount)

    if (modes.isTrainingMode()) trainingTasks.countTaskValue(Tasks.runQuality, this.getQualityRun())

  }

  /**
   * Vratenie kvality behu
   * @returns Kvalita behu
   */
  public getQualityRun(): number {

    const sum = this.runQualities.reduce((acc, val) => (acc += val), 0)
    return this.runQualities.length === 0 ? 0 : Math.round(sum / this.runQualities.length * 100) / 100

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.setLungeInfoFill()

    if (player.speedLockActive) return
    if (player.velocityManager.framesUnderIdeal > 1 * fpsManager.fpsLimit) {

      tutorialFlow.eventActionTrigger(TutorialEventType.tooSlow)

    }
    if (player.velocityManager.getSpeedPower() > 210) {

      tutorialFlow.eventActionTrigger(TutorialEventType.tooFast)

    }
    if (player.velocityManager.framesUnderIdeal === 0 && player.velocityManager.getSpeedPower() < 210) {

      tutorialState().showInstructionBox = false

    }

  }

  /**
   * Inputy pri behu
   */
  public handleInputs(): void {

    if (!this.isActive) return
    player.velocityManager.handleInputs()
    if ((modes.isTutorial() && !tutorialFlow.isSecondRun)) return
    player.handleLunge()

  }

  /**
   * Nastavi hodnotu pre lunge info bar
   */
  private setLungeInfoFill(): void {

    if (!this.showLungeInfo) return

    this.frameCounterLunge += 1

    if (player.lungeState === LungeStates.forward) {

      this.lungeInfoFill = player.lungeActualFrame / player.getLungeMaxFrames() * 100

    }
    if (player.lungeState === LungeStates.backward) {

      this.lungeInfoFill = (1 - (player.lungeActualFrame / player.getLungeMaxFrames())) * 100

    }

    const { worldEnvLinesManager } = player
    const meters = Math.round(worldEnvLinesManager.lungeOffset / worldEnvLinesManager.oneMeterInPercent * 100) / 100

    endManager.lungeQuality = this.lungeInfoFill / 100
    this.setLungeInfoData(meters)
    lungeState().$patch({
      showLungeInfo: this.frameCounterLunge > 50 ? true : false,
      text: `+${meters} m`,
      fill: this.lungeInfoFill,
      color: this.lungeColor,
      messageType: '',
      showInfo: this.frameCounterLunge > 55 ? true : false
    })

  }

  /**
   * Nastavenie dat pre lungeInfo podla metrov
   * @param meters - number
   */
  private setLungeInfoData(meters: number): void {

    if (meters <= (lungeConfig.lungeOffsetMax * 0.5)) {

      this.lungeColor = 'red'
      if ([LungeStates.forward, LungeStates.none].includes(player.lungeState)) {

        this.lungeMessageType = 'too-late'

      } else {

        this.lungeMessageType = 'too-early'

      }

    } else if (meters <= (lungeConfig.lungeOffsetMax * 0.75)) {

      this.lungeColor = 'orange'
      this.lungeMessageType = 'good'

    } else if (meters <= (lungeConfig.lungeOffsetMax * 0.99)) {

      this.lungeColor = 'yellow'
      this.lungeMessageType = 'excellent'

    } else {

      this.lungeColor = 'green'
      this.lungeMessageType = 'perfect'

    }

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    this.isActive = false
    player.deactivateSpeedLock()
    opponentsManager.getOpponents().forEach(opponent => {

      opponent.deactivateSpeedLock()

    })
    console.warn('game phase ended')

    this.callbackEnd()

    const { worldEnvLinesManager } = player
    const meters = Math.round(worldEnvLinesManager.lungeOffset / worldEnvLinesManager.oneMeterInPercent * 100) / 100
    lungeState().$patch({
      showLungeInfo: this.showLungeInfo,
      text: `+${meters} m`,
      fill: this.lungeInfoFill,
      color: this.lungeColor,
      messageType: this.lungeMessageType,
      showInfo: false
    })
    if (this.lungeMessageType === 'perfect') endManager.perfectLunge = true
    worldEnv.setVisibilityTextPlayer(false)
    this.showLungeInfo = false

    gsap.to({}, {
      onComplete: () => {

        lungeState().$patch({
          showLungeInfo: this.showLungeInfo,
          text: '',
          fill: 0,
          color: '',
          messageType: '',
          showInfo: false
        })

      },
      duration: 1.5
    })

    this.frameCounterLunge = 0

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
